import cache from 'memory-cache'

export const setCache = (key: string, value: string, ttl: number): void => {
  cache.put(key, value, ttl)
}

export const getCache = (key: string): string => {
  return cache.get(key)
}

export const deleteCache = (key: string): string => {
  return cache.del(key)
}
