import { SSM } from 'aws-sdk'
import { getCache, setCache } from 'services/cache.service'
import { cacheParams } from './constants'
import { logger } from './Logger'
import { UserAccount } from './types'

export const getApplicationKey = async (): Promise<string> => {
  const enableCaching = process.env.ENABLE_CACHING === 'true'
  logger.info(`enableCaching: ${enableCaching}`)
  const { key, ttl } = cacheParams.cr3ApiKey
  let applicationKey: string | null = enableCaching ? getCache(key) : null
  if (!applicationKey) {
    enableCaching && logger.info(`getApplicationKey cache expired`)
    const ssm = new SSM({
      region: process.env.REGION,
      apiVersion: '2014-11-06',
    })
    const params = {
      Name: process.env.SSM_WEBSITE_REGISTER_USER_ROTATED_API_KEY || '',
      WithDecryption: true,
    }
    const request = await ssm.getParameter(params).promise()
    applicationKey = request?.Parameter?.Value as string
    if (applicationKey && enableCaching) {
      setCache(key, applicationKey, ttl)
    }
  }
  return applicationKey
}

export const getUserParamsByClientId = async (clientId: string): Promise<UserAccount | undefined> => {
  try {
    if (clientId) {
      const ssm = new SSM({
        region: process.env.REGION,
        apiVersion: '2014-11-06',
      })
      const params = {
        Name: process.env.ROTATE_REFRESHTOKEN_USER || '',
        WithDecryption: true,
      }
      const request = await ssm.getParameter(params).promise()
      const ret = JSON.parse(request?.Parameter?.Value as string)
      return ret[clientId]
    }
  } catch (err) {
    logger.error(`getUserParamsByClientId from SSM error: ${JSON.stringify(err)}`)
    throw err
  }
}
