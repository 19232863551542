// core
import React, { useState, useCallback } from 'react'

const SettingsContext = React.createContext<any>(null)

export const SettingsProvider = ({ children, settings }) => {
  const [currentSettings, setCurrentSettings] = useState(settings)

  const saveSettings = useCallback((values) => {
    setCurrentSettings(values)
  }, [])

  return (
    <SettingsContext.Provider value={{ settings: currentSettings, saveSettings }}>{children}</SettingsContext.Provider>
  )
}

export const SettingsConsumer = SettingsContext.Consumer

export default SettingsContext
