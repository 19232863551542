import Router from 'next/router'
import { NextPageContext } from 'next'

export const redirect = (target, reload: boolean = false, ctx?: NextPageContext) => {
  if (ctx && ctx.res) {
    // If on the server, an HTTP 303 response with a "Location"
    // is used to redirect.
    // 302: https://serverfault.com/questions/391181/examples-of-302-vs-303
    ctx.res.writeHead(302, { Location: target })
    ctx.res.end()
  } else {
    // On the browser, next/router is used to "replace" the current
    // location for the new one, removing it from history.
    if (reload) {
      window.location.href = target
    } else {
      Router.replace(target)
    }
  }
}
