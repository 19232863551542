export const CashrewardsClientId = 1000000

export const MoneyMeClientId = 1000033

export const CORSOptions = {
  ...(process.env.NODE_ENV !== 'development' && { origin: /cashrewards\.com\.au$/ }),
}

export const MinPwdLength = 8
export const MaxPwdLength = 20

export const auCountryCode = '+61'
export const nzCountryCode = '+64'

export const SkipVerifyOTPMobile = /^\+6149157015[6-9]$/

export const contactUsUrl = '//help.cashrewards.com.au/support/tickets/new'

export enum ErrorMsg {
  UnableToVerifyPromoCode = 'Unfortunately we are unable to verify Promo Code right now. Please contact Customer Service to rectify this issue.',
  InvalidPromoCode = 'Invalid Promo Code',
}

export enum ApiErrorMsg {
  NoAuthorizationHeader = `Authorization header is required`,
  NotBearerToken = 'Token type must be Bearer',
  InvalidToken = 'Token is invalid',
  FaileToGetToken = 'Failed to get token',
  FailToValidateToken = 'Failed to validate token',
  WrongApiMethod = 'Wrong api method',
  InvalidInput = 'Invalid input',
  ErrorInRefreshToken = 'Error in refresh auth token',
  InternalError = 'Unfortunately we are unable to rotate refreshToken right now. Please contact Customer Service to fix this issue.',
  InvalidUserToRotateRefreshToken = 'Invalid user to rotate the refreshToken',
  GenericSignInError = 'Incorrect User Name or Password',
  SignUpCRMemberError = 'Could not signup with cashrewards',
  InvalidCognitoUserPoolId = 'Invalid Cognito user pool ID',
  InvalidOTPCode = 'Invalid OTP code',
  InvalidPromoCode = 'Invalid Promo Code',
}

export enum PremiumStatus {
  NotEnrolled = 0,
  Enrolled = 1,
  OptOut = 999,
}

export enum Platform {
  Web = 1,
  App = 2,
}

export enum SignupSource {
  CrWeb = 1,
  CrMobileApp = 2,
  MmeMobileApp = 3,
}
export const ReferAMateCoupon = 'referamate'

export const hourMilliseconds = 60 * 60 * 1000
export const fortnightMilliseconds = 14 * 24 * hourMilliseconds

export const cacheParams = {
  cr3ApiKey: {
    key: 'cr3ApiKey',
    ttl: hourMilliseconds,
  },
  partnerTableKey: {
    key: 'partnerTableKey',
    ttl: fortnightMilliseconds,
  },
}

export enum API_KEY {
  RESET_PASSWORD_TOKEN_VALIDATE = 'x-orphan-mobile-update',
}
