import { CognitoIdentityServiceProvider } from 'aws-sdk'
import { stringList } from 'aws-sdk/clients/datapipeline'
import { logger } from 'core/Logger'
import { getCache, setCache } from 'services/cache.service'
import { hourMilliseconds } from 'core/constants'

const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
  apiVersion: '2016-04-18',
  region: process.env.REGION,
})

export const getCallbackUrls = async (userPoolId, client_id) => {
  const params = {
    UserPoolId: userPoolId,
    ClientId: client_id,
  }

  const callbackUrlKey: string = `${userPoolId}.${client_id}`
  let callbackUrls: stringList = getCache(callbackUrlKey)?.split(',')
  if (!callbackUrls) {
    const request = await cognitoidentityserviceprovider.describeUserPoolClient(params).promise()
    logger.info(request?.UserPoolClient?.CallbackURLs)
    callbackUrls = request?.UserPoolClient?.CallbackURLs as stringList
    const callbackString = callbackUrls?.join(',')
    setCache(callbackUrlKey, callbackString, hourMilliseconds)
  }
  return callbackUrls
}
