export const _parseError = (error) => {
  const jsonRegex = /.*{(.*)}.*/g
  const match = jsonRegex.exec(error?.error?.message)
  const jsonStr = match?.[1] as string
  try {
    return JSON.parse(`{${jsonStr}}`)
  } catch (JSONException) {
    if (error?.error?.message && error?.error?.code !== 'ETIMEDOUT') {
      return error?.error
    }
    return {
      code: 'SE-000',
      message: 'We’re sorry, a system error occured. Your MoneyMe card cannot be linked to Cashrewards at this time.',
    }
  }
}
